import styled from "styled-components";

export const Container = styled.section`
  margin-top: 10rem;

  header {
    text-align: center;
    h2 {
      text-align: center;
      font-size: 4rem;
    }
    p {
      color: var(--green);
      font-weight: 500;
    }
  }

  .contacts {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 1.5rem;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      max-width: 30rem;
      gap: 2rem;
      background-color: var(--green);
      border-radius: 1.4rem;
      padding: 1.6rem 2.8rem;
      transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
      position: relative;
      cursor: pointer; /* Pointer cursor to indicate interactivity */

      img {
        width: 4rem;
        transition: transform 0.3s ease; /* Smooth transition for the icon */
      }

      span {
        transition: transform 0.3s ease; /* Smooth transition for text */
      }

      a {
        color: var(--black);
        font-weight: 500;
        transition: color 0.3s ease; /* Gradual color change */
      }

      &:hover {
          background-color: var(--pink);
          transform: translateY(-5px) scale(1.05); /* Lift effect and slight scaling */
          box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4); /* Increased shadow for more depth */
          border: 2px solid var(--dark-pink); /* Optional border effect for emphasis */
          transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease; /* Smooth transition for all properties */
    
        img {
          transform: scale(1.1) rotate(3deg); /* Slightly increase icon size */
        }

        span {
          transform: scale(1.05); /* Slightly increase text size */
        }

        a {
          color: #FFF; /* Change link color to white */
          font-weight: 600; /* Make text bold on hover */
          transition: color 0.3s ease; /* Gradual color change for text */
        }
      }
    }
  }

  @media (max-width: 960px) {
    .contacts {
      flex-direction: column;

      div {
        width: 100%;
      }
    }
  }
`;
