import { Container } from './styles'
import { BrowserRouter as Router } from 'react-router-dom'
import { NavHashLink, HashLink } from 'react-router-hash-link'
import { useState } from 'react'
import Resume from '../../assets/james_supagit.pdf'
import { toast, Toaster } from 'react-hot-toast'

export function Header() {
  const [isActive, setActive] = useState(false)

  // Function to display a toast with a different emoji for each section
  function handleNavigationClick(topic: string) {
    let emoji = '';

    // Set the emoji based on the section clicked
    switch (topic) {
      case 'Home':
        emoji = '🏠'; // Home emoji
        break;
      case 'About me':
        emoji = '👤'; // About me emoji
        break;
      case 'Project':
        emoji = '💼'; // Project emoji
        break;
      case 'Contact':
        emoji = '📞'; // Contact emoji
        break;
      default:
        emoji = '🔔'; // Default notification emoji
    }

    // Show toast with the chosen emoji
    toast(`You clicked on ${topic} ${emoji}`, {
      icon: emoji,
      style: {
        borderRadius: '100px',
        background: '#333',
        color: '#fff',
      },
    })
  }

  function toggleTheme() {
    let html = document.getElementsByTagName('html')[0]
    html.classList.toggle('light')

    // Trigger the toast notification when the theme is toggled
    toast('Switch Theme!', {
      icon: '👏',
      style: {
        borderRadius: '100px',
        background: '#333',
        color: '#fff',
      },
    })
  }

  function closeMenu() {
    setActive(false)
  }

  return (
    <Container className="header-fixed">
      <Router>
        <HashLink smooth to="#home" className="logo">
          <span>{"<James "}</span>
          <span>{" Supagit/>"}</span>
        </HashLink>
        <input
          onChange={toggleTheme}
          className="container_toggle"
          type="checkbox"
          id="switch"
          name="mode"
        />
        <label htmlFor="switch">Toggle</label>
        <nav className={isActive ? 'active' : ''}>
          <NavHashLink smooth to="#home" onClick={() => { handleNavigationClick('Home'); closeMenu(); }}>
            Home
          </NavHashLink>
          <NavHashLink smooth to="#about" onClick={() => { handleNavigationClick('About me'); closeMenu(); }}>
            About me
          </NavHashLink>
          <NavHashLink smooth to="#project" onClick={() => { handleNavigationClick('Project'); closeMenu(); }}>
            Project
          </NavHashLink>
          <NavHashLink smooth to="#contact" onClick={() => { handleNavigationClick('Contact'); closeMenu(); }}>
            Contact
          </NavHashLink>
          <a href={Resume} download className="button">
            Resume
          </a>
        </nav>
        <div
          aria-expanded={isActive ? 'true' : 'false'}
          aria-haspopup="true"
          aria-label={isActive ? 'Fechar menu' : 'Abrir menu'}
          className={isActive ? 'menu active' : 'menu'}
          onClick={() => {
            setActive(!isActive)
          }}
        ></div>
      
      </Router>
    </Container>
  )
}
