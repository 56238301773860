import styled from "styled-components";

// Container for the News section
export const Container = styled.div`
  width: auto;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

// Header for the News section, adjusted for alignment
export const NewsHeader = styled.h2`
  font-size: 5vw;
  margin-bottom: 0;
  padding-bottom: 15px;
  line-height: 1.5;
  text-align: center;
`;

// Content for the News section
export const NewsContent = styled.div`
  margin-top: 20px;
  p {
    font-size: 3vw;
    color: #333;
    margin: 0 auto;
    max-width: 100%;
  }
`;

// Carousel container with responsiveness
export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

// Flex container for the carousel images
export const CarouselImages = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
`;

// Smart carousel image styling
export const CarouselImage = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: center;
`;

// Arrow button styling for navigation
export const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1;

  &.prev {
    left: 10px;
  }

  &.next {
    right: 10px;
  }
`;
